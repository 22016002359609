import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Divider from '../../generic/Divider';
import { localizeDate } from '../../../lib/utils/format';
import FormButton from "../../generic/buttons/main";
import './style.scss'
import Feedback from "../../common/Feedback";
import {BASE_SILAVETRA_URL} from "../../../lib/api/globalState";
import i18next from "i18next";
import useFinal from "./useFinal";
import {TOrder} from "../../../lib/utils/types";
import CalendarButton from '../../common/CalendarEvents/Button';
import useCountry from '../../../lib/utils/hooks/useCountry';
import InsuranceModal from 'components/generic/InsuranceModal';
import {useInjection} from "brandi-react";
import {GlobalSettingsModelStoreToken} from "../../../entities/globalConfig/model";
import _ from 'lodash';

type TProps = {
    payingUser: any
    setOrder: Dispatch<SetStateAction<TOrder | undefined>>
    orderId: string | number
    onNewBookingClick: () => void
    order?: TOrder
}

const Final = ({ payingUser, setOrder, order, orderId, onNewBookingClick }: TProps) => {
    useFinal(orderId, setOrder)
    const {t, language} = i18next;
    const [insuranceModalVisible, setInsuranceModalVisible] = useState(_.isNull(payingUser?.is_insurance));
    const handleInsuranceModalVisible = () => {
      setInsuranceModalVisible(!insuranceModalVisible);
    }
    const globalSettings = useInjection(GlobalSettingsModelStoreToken);

    useEffect(() => {
      if (payingUser && 'is_insurance' in payingUser && globalSettings.config['insurance_available']) {
        setInsuranceModalVisible(_.isNull(payingUser?.is_insurance))
      }
    }, [payingUser])

    const country = useCountry();
  return (

    <div className="final">
        {!order?.hasPaid
            ? <div className="final__title">{t('final.processing')}</div>
            : <div className="final__title">{t('final.eventSuccess', {date: localizeDate(order?.slot?.start_date, language)})}</div>
        }
      <div className="final__title">
          {t('final.materials')}
      </div>
      <div className="final__docs-list">
        {
          country === 'RU' && <>
            <a href="https://silavetra.com/magazine/pervye-parusnye-trenirovki" target="_blank" rel="noopener noreferrer" className="final__docs-item">
              {t('final.firstTrains')}
            </a>
            <a href="https://silavetra.com/what_need_to_take_oreshka" target="_blank" rel="noopener noreferrer" className="final__docs-item">
              {t('final.takeOnTrain')}
            </a>
            <a href="https://silavetra.com/magazine/kak-odetsya-na-luch" target="_blank" rel="noopener noreferrer" className="final__docs-item">
              {t('final.luchClothes')}
            </a>
            <a href="https://silavetra.com/perchatki-dlya-yahtinga-ispytanie-v-boevyh-usloviyah" target="_blank" rel="noopener noreferrer" className="final__docs-item">
              {t('final.gloves')}
            </a>
            <a href="https://silavetra.com/experiment" target="_blank" rel="noopener noreferrer" className="final__docs-item">
              {t('final.shoe')}
            </a>
          </>
        }
          {!!order?.slot?.extraMaterials?.length
          && order?.slot?.extraMaterials.map((material: any) => <a href={material.link} target="_blank"
                                                           rel="noopener noreferrer"
                                                           className="final__docs-item">{material.name}</a>)}
      </div>
      {order && order.slot && <CalendarButton
        name={order.slot.name}
        startDate={order.slot.start_date}
        endDate={order.slot.end_date}
        startTime={order.slot.start_time}
        endTime={order.slot.end_time}
      />}
      <a href={BASE_SILAVETRA_URL} className="button_links">
          <FormButton value={t('final.onTheMain')} type={'button'} disabled={false} className={undefined}
                      onClick={undefined} />
      </a>
      <FormButton value={t('final.bookMore')} onClick={() => {
          onNewBookingClick();
      }} type={'button'} disabled={false} className={undefined}/>
      <Divider />
    <Feedback />
    <br/>
    {insuranceModalVisible &&
      <InsuranceModal
        handleModalVisible={handleInsuranceModalVisible}
        defaultValues={{...payingUser, ...order?.participants[0], orderId: order?.id}}
      />
    }
    </div>
  )
}

export default Final;
